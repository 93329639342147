@import './variables.scss';
@import './mixins.scss';

.storyTile {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  @include flexCol;
  justify-content: center;
  align-items: center;
  position: relative;

  &__overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &__videoBG {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__button {
    margin-top: 1.5rem;
    button {
      border-radius: 50px;
      border: 3px solid var(--amica-teal, #00a88f);
      background: rgba(0, 0, 0, 0.25);
      @include flexRow;
      color: #fff;
      text-align: center;
      font-family: apercuReg;
      font-size: 24px;
      font-weight: 400;
      line-height: 30px; /* 125% */
      padding: 24px 40px;
      &:hover {
        background-color: rgba(47, 143, 130, 0.25);
        cursor: pointer;
      }
      img {
        margin-left: 1rem;
      }
    }
  }
  &__text {
    z-index: 5;
    width: 720px;
    @media screen and (max-width: 720px) {
      width: 85%;
    }

    @include flexCol;
    &__header {
      @include flexCol;
      :nth-child(1) {
        color: #fff;
        text-align: center;
        font-family: apercuReg;
        font-size: 51px;
        line-height: 83px; /* 162.745% */
        text-transform: uppercase;
        @media screen and (max-width: 720px) {
          font-size: 24px;
          line-height: 44px; /* 183.333% */
        }
      }
      :nth-child(2) {
        color: #fff;
        font-family: amicaRhymesThinIt;
        font-size: 114px;
        font-weight: 400;
        line-height: 83px;
        @media screen and (max-width: 720px) {
          font-size: 57px;
          line-height: 44px; /* 77.193% */
        }
      }
    }
    &__horiLine {
      margin: 2rem 0;
      width: 58px;
      height: 4px;
      background-color: $amicaTeal;
    }
    &__dateInfo {
      color: #fff;
      text-align: center;
      font-family: apercuMono;
      font-size: 20px;
      line-height: 30px; /* 150% */
    }
    &__pg {
      width: 720px;
      margin-top: 2rem;
      @media screen and (max-width: 720px) {
        width: 85%;
      }
    }
  }
  &__popout {
    @include flexCol;
    background-color: #000;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    @media screen and (max-width: 720px) {
      justify-content: center;
    }
    &__closeButton {
      position: fixed;
      top: 2rem;
      right: 3rem;
      z-index: 10;
      @media screen and (max-width: 720px) {
        right: 2rem;
      }
      button {
        background: none;
        border: none;
      }
    }
    &__video {
      padding-top: 56.25%;
      position: relative;
      width: 100%;
      height: 100vh;
      align-self: center;
      &__player {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
