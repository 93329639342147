@import './variables.scss';
@import './mixins.scss';

.carouselSection {
  background: $linearBgGradient;
  width: 100%;
  // padding-bottom: 8rem;
  @media screen and (max-width: 1100px) {
    padding-bottom: 3rem;
  }
  @include flexCol;
  &__quoteCont {
    @include flexCol;
    width: 85%;
    @media screen and (max-width: 720px) {
      margin-top: 4rem;
    }
    &__imageCont {
      img {
        width: 65px;
      }
    }
    &__textCont {
      margin-top: 2rem;
      p {
        color: #fff;
        text-align: center;
        font-family: amicaRhymesReg;
        font-size: 48px;
        line-height: 67px; /* 139.583% */
        @media screen and (max-width: 720px) {
          font-size: 36px;
          line-height: 48px; /* 133.333% */
        }
      }
    }
  }
}
