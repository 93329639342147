@import './variables.scss';
@import './mixins.scss';

.shareYourStory {
  // height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  @include flexCol;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0rem 0;
  @media screen and (max-width: 1100px) {
    @include flexCol;
    padding: 2rem 0;
    padding-top: 0;
  }
  // &__overlay {
  //   width: 100%;
  //   height: 100vh;
  //   position: absolute;
  //   z-index: 2;
  //   background-color: rgba(0, 0, 0, 0.5);
  // }
  &__videoBG {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    @include flexRow;
    justify-content: center;
    @media screen and (max-width: 720px) {
      @include flexCol;
    }
  }
  .reverseCol {
    @media screen and (max-width: 720px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  &__form {
    width: 100%;
    margin-top: 8rem;
    height: auto;
    @media screen and (max-width: 720px) {
      margin-top: 3rem;
    }
  }
  .marginTop {
    @media screen and (max-width: 1100px) {
      margin-top: 3rem;
    }
  }
  &__img {
    z-index: 5;
    width: 50%;
    @include flexCol;
    @media screen and (max-width: 1100px) {
      width: 100%;
      margin-bottom: 2rem;
    }

    img {
      @media screen and (max-width: 1100px) {
        width: 100%;
      }
    }
  }
  &__text {
    z-index: 5;
    width: 50%;
    margin-left: 2rem;
    @include flexCol;
    @media screen and (max-width: 1100px) {
      width: 85%;
      margin-left: 0;
    }
    &__header {
      @include flexCol;
      :nth-child(1) {
        color: #fff;
        text-align: center;
        font-family: apercuReg;
        font-size: 51px;
        line-height: 83px; /* 162.745% */
        text-transform: uppercase;
        @media screen and (max-width: 1100px) {
          font-size: 24px;
          line-height: 44px; /* 183.333% */
        }
      }
      :nth-child(2) {
        color: #fff;
        font-family: amicaRhymesThinIt;
        font-size: 114px;
        font-weight: 400;
        line-height: 83px;
        @media screen and (max-width: 1100px) {
          font-size: 57px;
          line-height: 44px; /* 77.193% */
        }
      }
    }
    &__horiLine {
      margin: 2rem 0;
      width: 58px;
      height: 4px;
      background-color: $amicaTeal;
    }
    &__dateInfo {
      color: #fff;
      text-align: center;
      font-family: apercuMono;
      font-size: 20px;
      line-height: 30px; /* 150% */
    }
    &__pg {
      width: 70%;
      margin-top: 1rem;
      p {
        margin-top: 1rem;
        text-align: left;
      }
      @media screen and (max-width: 1100px) {
        width: 85%;
      }
      .bold {
        font-family: apercuBold;
      }
      .boldItalic {
        font-family: apercuItalic;
        font-size: 17px;
        line-height: 30px;
        -webkit-text-stroke-width: 1.1px;
        -webkit-text-stroke-color: white;
        letter-spacing: 1px;
      }
    }
  }
  &__button {
    z-index: 10;
    margin-top: 2rem;
    button {
      @include standardButton;
    }
  }
}
.textarea {
  border-radius: 20px;
}
.paddingSpecial {
  padding-top: 10rem;
  padding-bottom: 8rem;
  background-color: #000;
  @media screen and (max-width: 720px) {
    padding-top: 0;
  }
}
