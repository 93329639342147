@mixin flexCol {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@mixin flexRow {
  display: flex;
  align-items: center;
}
@mixin photoBg {
  background-size: cover;
  background-position: center;
}
@mixin buttonStyleRemove {
  border: none;
  background: none;
}
@mixin standardButton {
  border-radius: 50px;
  border: 2px solid var(--amica-teal, #00a88f);
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  font-family: apercuReg;
  font-size: 18px;
  font-style: normal;
  line-height: 30px; /* 125% */
  padding: 18px 30px;
  &:hover {
    background-color: rgba(47, 143, 130, 0.25);
    cursor: pointer;
  }
  @media screen and (max-width: 720px) {
    padding: 18px 30px;
    font-size: 18px;
    line-height: 30px; /* 166.667% */
  }
}
