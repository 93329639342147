p {
  color: #fff;
  text-align: center;
  font-family: apercuReg;
  font-size: 18px;
  line-height: 28px; /* 150% */
  @media screen and (max-width: 720px) {
    font-size: 16px;
    line-height: 24px; /* 150% */
  }
}
p.specialText {
  font-family: amicaRhymesThinIt;
  font-size: 28px;
  line-height: 40px;
  @media screen and (max-width) {
    font-size: 24px;
    line-height: 34px;
  }
}
