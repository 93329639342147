@import './mixins.scss';
@import './variables.scss';

.description {
  background-image: url('../assets/descripBg.jpg');
  height: 100vh;
  @include photoBg;
  @include flexCol;
  justify-content: center;
  align-items: flex-start;
  padding-left: 6rem;
  @media screen and (max-width: 720px) {
    padding-left: 0;
    align-items: center;
    background-image: url('../assets/descripBgM.jpg');
    height: auto;
    padding: 2rem 0rem;
  }
  :nth-child(1) {
    font-size: 22px;
    font-family: apercuBold;
  }
  p {
    width: 514px;
    text-align: left;
    margin: 0.5rem 0;
    font-size: 18px;
    line-height: 30px; /* 150% */
    @media screen and (max-width: 720px) {
      width: 85%;
      // font-size: 16;
    }
    .bold {
      font-size: 18px;
      line-height: 30px;
    }
    .boldItalic {
      font-family: apercuItalic;
      font-size: 17px;
      line-height: 30px;
      -webkit-text-stroke-width: 1.1px;
      -webkit-text-stroke-color: white;
      letter-spacing: 1px;
    }
  }
}
p.specialText {
  font-family: amicaRhymesThinIt;
  font-size: 28px;
  line-height: 40px;
  @media screen and (max-width) {
    font-size: 24px;
    line-height: 34px;
  }
}
