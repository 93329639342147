@import './variables.scss';
@import './mixins.scss';

.footer {
  @include flexRow;
  justify-content: center;
  background-color: #000;
  padding: 30px 0;
  &__links {
    width: auto;
    a {
      color: #fff;
      text-align: center;
      font-family: apercuReg;
      font-size: 14px;
      line-height: 20px; /* 142.857% */
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
