@import './variables.scss';
@import './mixins.scss';

.cta {
  background-image: url('../assets/ctaBG.jpg');
  @include photoBg;
  height: 100vh;
  width: 100%;
  @include flexRow;
  justify-content: flex-start;
  padding: 0 8rem;
  @media screen and (max-width: 720px) {
    padding: 2rem;
    justify-content: center;
    height: auto;
  }
  &__content {
    width: 514px;
    @include flexCol;
    align-items: flex-start;
    @media screen and (max-width: 720px) {
      width: 85%;
    }
    &__heading {
      font-family: apercuBold;
      font-size: 24px;
      margin-bottom: 2rem;
    }
    img {
      margin: 2rem 0;
      width: 230px;
      @media screen and (max-width: 720px) {
        margin: 1rem;
      }
    }
    p {
      text-align: left;
      margin-bottom: 1rem;
    }
    p.specialText {
      font-family: amicaRhymesThinIt;
      font-size: 28px;
      line-height: 40px;
      @media screen and (max-width) {
        font-size: 24px;
        line-height: 34px;
      }
    }
    ul {
      margin-left: 2rem;
      margin-bottom: 1rem;
      li {
        color: #fff;
        text-align: left;
        font-family: apercuReg;
        font-size: 18px;
        line-height: 28px; /* 150% */
        @media screen and (max-width: 720px) {
          font-size: 16px;
          line-height: 24px; /* 150% */
        }
      }
    }
    a {
      @media screen and (max-width: 720px) {
        align-self: center;
      }
    }
    button {
      @include standardButton;
      margin-top: 2rem;
      @media screen and (max-width: 720px) {
        margin-top: 1rem;
      }
    }
  }
}
