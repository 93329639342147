@font-face {
  font-family: 'apercuReg';
  src: url('./fonts/Apercu/ApercuPro-Regular.otf');
}
@font-face {
  font-family: 'apercuBold';
  src: url('./fonts/Apercu/ApercuPro-Bold.otf');
}
@font-face {
  font-family: 'apercuLight';
  src: url('./fonts/Apercu/ApercuCondensedPro-Light.otf');
}
@font-face {
  font-family: 'apercuItalic';
  src: url('./fonts/Apercu/ApercuPro-Italic.otf');
}
@font-face {
  font-family: 'apercuMono';
  src: url('./fonts/Apercu/ApercuMonoPro-Regular.otf');
}
@font-face {
  font-family: 'amicaRhymesReg';
  src: url('./fonts/AmicaRhymes/AmicaRhymes-Regular.otf');
}
@font-face {
  font-family: 'amicaRhymesIt';
  src: url('./fonts/AmicaRhymes/AmicaRhymes-Italic.otf');
}
@font-face {
  font-family: 'amicaRhymesThin';
  src: url('./fonts/AmicaRhymes/AmicaRhymes-Thin.otf');
}
@font-face {
  font-family: 'amicaRhymesThinIt';
  src: url('./fonts/AmicaRhymes/AmicaRhymes-ThinItalic.otf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
