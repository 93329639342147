@import './mixins.scss';
@import './variables.scss';
.theStories {
  @include flexCol;
  &__introText {
    @include flexCol;
    :nth-child(1) {
      font-family: apercuReg;
      font-size: 51px;
      line-height: 83px;
    }
    :nth-child(2) {
      font-family: amicaRhymesThinIt;
      font-size: 114px;
      line-height: 83px;
    }
    .horiLine {
      margin: 3rem 0;
      width: 58px;
      height: 4px;
      background-color: $amicaTeal;
    }
    :nth-child(4) {
      width: 50%;
      @media screen and (max-width: 720px) {
        width: 80%;
      }
    }
  }
  &__grid {
    padding: 0 106px;
    display: grid;
    @media screen and (max-width: 720px) {
      padding: 0;
    }
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 4rem;
    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 720px) {
      grid-template-columns: 1fr;
    }
  }
}

.tile {
  @include flexCol;
  position: relative;
  height: 398px;
  width: 398px;
  @media screen and (max-width: 720px) {
    width: 100vw;
    heigth: 100vw;
  }
  &__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
  &__videoBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 398px;
    height: 398px;
    overflow: hidden;
    @media screen and (max-width: 720px) {
      width: 100%;
      height: 100vw;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__text {
    @include flexCol;
    margin-top: 7rem;
    z-index: 2;
    :nth-child(1) {
      font-family: apercuReg;
      font-size: 24px;
      line-height: 44px; /* 183.333% */
    }
    :nth-child(2) {
      font-family: amicaRhymesThinIt;
      font-size: 57px;
      line-height: 44px;
    }
    .horiLine {
      margin: 1rem 0;
      width: 58px;
      height: 4px;
      background-color: $amicaTeal;
    }
  }
  &__button {
    z-index: 2;
    margin-top: 2rem;
    button {
      @include standardButton;
      @include flexRow;
      align-items: center;
      img {
        margin-left: 1rem;
        @include flexRow;
      }
    }
  }
}
