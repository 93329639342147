@import './mixins.scss';
@import './variables.scss';

.navMob {
  position: relative;
  &__icon {
    button {
      @include buttonStyleRemove;
      position: absolute;
      top: 1rem;
      right: 5%;
      z-index: 13;
    }
  }
  &__drawer {
    height: 100vh;
    width: 100vw;
    background-image: url('../assets/navDrawerBg.jpg');
    padding-top: 124px;
    z-index: 12;
    @include photoBg;
    @include flexCol;
    &__links {
      font-family: apercuBold;
      font-size: 16px;
      line-height: 24px; /* 150% */
      color: #fff;
      text-align: center;
      margin-top: 2rem;

      .mobNavlink {
        margin: 1rem 0;
        a {
          text-decoration: none;
          color: #fff;
        }
        :nth-child(2) {
          font-family: apercuReg;
          font-size: 10px;
          line-height: 15px; /* 150% */
        }
      }
    }
    &__amicaLogo {
      width: 93px;
      margin-top: 2rem;
      img {
        width: 100%;
      }
    }
  }
}
