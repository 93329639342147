@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
$gallery-font: 'Montserrat';

//background
$gallery-background-color: #000000;

//padding for gallery__content
$gallery-padding-desktop: 0 0 0 0;
$gallery-padding-mobile: 48px 0 84px 0;

//image styling
$image-aspect-ratio: 1.5; //image width divided by height
$image-max-width: 780px; //image width for screen width >= 975px
$image-width-desktop: 80vw; //image width for screen width < 975px
$image-width-mobile: 90vw; //image width for screen width < 768px
$padding-images-mobile: 2vw; //padding between images for mobile view

//arrows
$arrow-height: 64px;
$arrow-width: 64px;
$arrow-margin: 10px; //space between arrow and center of the screen
$arrow-opacity: 0.6;

//disclaimer styling
$disclaimer-font: 11px/14px $gallery-font;
$disclaimer-font-text-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.5);
$disclaimer-color: #ffffff;

//caption styling
$caption-font: 11px $gallery-font;
$caption-color: #f0f0f0;
$caption-width-desktop: 455px;
$caption-width-mobile: 80%;
$caption-text-align: center;
$caption-height: 120px;

//swipe text styling for mobile
$swipe-color: #e6e6e6;
$swipe-font: bold 12px $gallery-font;
$swipe-letter-spacing: 4%;

//arrow next to swipe for mobile
$arrowhead-width: 5px;
$arrowhead-margin-left: 5px;

//mixin for flex
@mixin flexCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin flexRow {
  display: flex;
  justify-content: center;
}

//edit anything below if needed
.galleryS {
  font-family: $gallery-font;
  width: 100%;
  position: relative;
  //   background-color: $gallery-background-color;

  overflow: hidden;
  @include flexRow;

  &__content {
    align-self: center;
    position: relative;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    // padding: $gallery-padding-mobile;
    padding: 2rem;
    @include flexCol;
    @media (min-width: 768px) {
      overflow: hidden;
      height: 70vw;
      max-height: 733px;
      padding: $gallery-padding-desktop;
    }
    @media (max-width: 767px) and (min-width: 461px) {
      max-height: 768px;
      height: 90vw;
      -webkit-overflow-scrolling: touch;
    }
    @media (max-width: 460px) {
      height: auto;
      -webkit-overflow-scrolling: touch;
    }

    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 10px;
    }
    &__imgs {
      &--desktop {
        position: relative;
        align-self: center;
        overflow: hidden;
        @include flexRow;
        @media (max-width: 767px) {
          display: none;
        }
      }

      &--mobile {
        position: relative;
        align-self: flex-start;
        margin-left: 5vw;
        margin-right: 5vw;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        @media (min-width: 767px) {
          display: none;
        }
      }
      &__img {
        align-self: center;
        overflow: hidden;
        width: $image-width-desktop;
        max-width: $image-max-width;
        @include flexCol;
        @media (max-width: 767px) {
          margin-right: $padding-images-mobile;
          width: $image-width-mobile;
        }

        &-container {
          position: relative;
          width: 100%;
          height: auto;
          aspect-ratio: $image-aspect-ratio;
          @include flexCol;
        }

        &-cap {
          height: $caption-height;
          width: 100%;
          align-self: center;
          @include flexRow;
          @media screen and (max-width: 720px) {
            height: 100px;
          }
        }
        &-cap--event {
          height: $caption-height;
          width: 100%;
          align-self: center;
          @include flexRow;
          @media screen and (max-width: 720px) {
            height: 20px;
          }
        }
      }
    }
  }
  &__img {
    align-self: center;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__caption {
    font: $caption-font;
    margin-top: 20px;
    color: $caption-color;
    width: $caption-width-desktop;
    text-align: $caption-text-align;
    @media (max-width: 786px) {
      width: $caption-width-mobile;
      align-self: flex-start;
    }
  }

  &__disclaimer {
    position: absolute;
    color: $disclaimer-color;
    z-index: 2;
    font: $disclaimer-font;
    text-shadow: $disclaimer-font-text-shadow;
    padding-left: 20px;
    align-self: flex-start;
    bottom: 0;
    @media (min-width: 1025px) {
      width: 45%;
    }
    @media (max-width: 1024px) and (min-width: 461px) {
      width: 80%;
    }
    @media (max-width: 460px) {
      width: 90%;
    }
  }
  //mobile swipe text
  &__swipe {
    align-self: center;
    position: absolute;
    bottom: 10px;
    transition: opacity 0.5s ease-out 0.5s;
    color: $swipe-color;
    font: $swipe-font;
    letter-spacing: $swipe-letter-spacing;
    @media (min-width: 767px) {
      display: none;
    }
    //mobile arrow next to swipe text
    &__arrowhead {
      margin-left: $arrowhead-margin-left;
      align-self: center;
      width: $arrowhead-width;
      height: auto;
    }
  }

  &__arrow {
    height: $arrow-height;
    width: $arrow-width;
    opacity: $arrow-opacity;
    align-self: center;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 767px) {
      display: none;
    }

    &--left {
      margin-right: $arrow-margin;
    }

    &--right {
      margin-left: $arrow-margin;
      transform: rotate(180deg);
    }
  }
}
