@import './variables.scss';
@import './mixins.scss';

.hero {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  @include flexCol;
  justify-content: center;
  align-items: center;
  position: relative;

  &__overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &__videoBG {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__img {
    // width: 720px;
    z-index: 3;
    @media screen and (max-width: 720px) {
      width: 80%;
      img {
        width: 100%;
      }
    }
    p {
      color: #fff;
      text-align: center;
      font-family: apercuReg;
      font-size: 24px;
      line-height: 34px; /* 141.667% */
    }
  }
  &__scrollIcon {
    position: absolute;
    bottom: 2rem;
    img {
    }
    @media screen and (max-width: 720px) {
      bottom: 2rem;
    }
  }
}
