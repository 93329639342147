@import './variables.scss';
@import './mixins.scss';

.navbar {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  @include flexRow;
  justify-content: space-between;
  position: fixed;
  top: 12px;
  width: 100%;
  padding: 2rem 2rem;
  margin-top: 1rem;
  z-index: 10;
  &__logoCont {
    width: 420px;
  }
  &__linksCont {
    @include flexRow;
    align-items: flex-start;
    &__link {
      margin: 0 1rem;
      a {
        font-family: apercuBold;
        color: #fff;
        font-size: 18px;
        line-height: 25px; /* 138.889% */
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        &:active {
          color: $amicaTeal;
        }
      }
      .active {
        color: $amicaTeal;
      }
      p {
        color: #fff;
        font-family: apercuReg;
        font-size: 14px;
        line-height: 18px; /* 128.571% */
      }
    }
  }
}
// .scrollingDown {
//   transform: translate(0, -110px);
//   transition: all 0.5s ease-in-out;
// }
// .scrollingUp {
//   transform: translate(0, 0);
//   transition: all 0.5s ease-in-out;
// }
// .hidden {
//   visibility: hidden;
//   @media screen and (max-width: 720px) {
//     visibility: visible;
//   }
// }
// .show {
//   visibility: visible;
// }
